export const env = {
  REACT_APP_REDEEM_BASEURL: "https://gamificationredemption.rewardrally.in",
  REACT_APP_GAMIFICATION_BASEURL: "https://gamifyapi.rewardrally.in",
  BLOB_URL: "https://prodgamificationui.blob.core.windows.net",
  CONTAINER_PATH: "sandbox-assets",
};

export const userData = {
  applicationId: "658ad84839f73503006d766b",
  clientId: "f9f95520-89d4-43b0-a326-affe40ff27f1",
  clientSecret: "pon8Q~dc-.o1wk~arnP6r9r3Zq8ls2emlMY1JbrW",
};
